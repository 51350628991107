.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.addButton {
  width: calc(100% - 1000px);
  padding: 15px;
  margin-bottom: 40px;
  font-size: 18px;
  border: 2px solid #007bff;
  border-radius: 25px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.addButton:hover {
  background-color: #007bff;
  color: white;
}

.navButtonsContainer {
  display: 'flex';
  flex-wrap: 'wrap';
  padding: '10px 0';
  justify-content: 'flex-end';
}

.navButton {
  padding: '10px 15px';
  margin: '0 5px';
  border: 'none';
  border-radius: '5px';
  background-color: '#007bff';
  color: 'white';
  cursor: 'pointer';
  font-size: '16px';
  outline: 'none';
  transition: 'background-color 0.3s ease';
}

.navButton:hover {
  background-color: #0056b3;
}

.voteButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.voteButton {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 5px; /* Added for spacing in case of wrap */
}

.voteButton:hover {
  background-color: #f0f0f0;
}

.voteButton span {
  margin-left: 5px;
}

@media (maxWidth: 768px) {
  .navButtonsContainer {
    flex-direction: 'row';
    justify-content: 'center';
    align-items: 'center';
    
  }

  .navButton {
    justify-content: 'left';
    flex-direction:'row';
    color: black;

  }

  .voteButtonsContainer {
    flex-direction: column;
}

  .voteButton {
      width: 100%;
      justify-content: center;
  }

}

/* For FAQPage.jsx */
.faqContainer {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.faqQuestion {
  color: #007bff;
  margin-top: 20px;
  font-weight: bold;
}

.faqAnswer {
  margin-bottom: 20px;
}

.auth-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 5px;
}

.input-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.auth-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.auth-button:hover {
  background-color: #0056b3;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searchBox {
  width: calc(100% - 30px);
  padding: 15px;
  font-size: 18px;
  border: 2px solid #007bff;
  border-radius: 25px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.searchButton {
  padding: 10px 15px;
  font-size: 18px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px; /* Optional: Adjust based on layout */
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #0056b3;
}




.faqContainer {
  padding: 20px;
}

.demoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adds space between demos */
}

.demo img {
  max-width: 100%;
  height: auto;
}

/* Style adjustments for smaller screens */
@media (max-width: 768px) {
  .demoContainer {
    flex-direction: column;
    align-items: center;
  }

  .demo {
    width: 100%; /* Makes demo containers full width */
  }

  .faqAnswer, .faqQuestion {
    text-align: center; /* Centers text for smaller screens */
  }
}



.title {
  text-align: center;
  font-size: 2.5rem;
  margin: 20px 0 10px 0;
  color: #007bff;
  font-family: Arial, sans-serif;
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.inputField {
  width: calc(100% - 30px);
  padding: 15px;
  margin-bottom: 20px;
  font-size: 18px;
  border: 2px solid #007bff;
  border-radius: 25px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

textarea.inputField {
  resize: vertical;
}

.button {
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #45a049;
}

.post {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.post h3 {
  color: #007bff;
}

.post p {
  margin-bottom: 10px;
}

.comment {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.comment p {
  margin-bottom: 5px;
}

.voteButton {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.voteButton:hover {
  background-color: #e0e0e0;
}

.post {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.post:hover {
  background-color: #f1f1f1;
}

